<template>
  <Swiper
    :modules="[Navigation, Pagination, Scrollbar, A11y, Thumbs, Zoom]"
    :style="{
      '--swiper-navigation-color': '#fff',
      '--swiper-pagination-color': '#fff',
    }"
    :centeredSlides="true"
    loop
    :breakpoints="productSlideBreakpoints"
    :navigation="true"
    :zoom="true"
    :thumbs="{ swiper: thumbsSwiper }"
    class="whiteNavigator"
  >
    <SwiperSlide
      v-for="(image, index) in selectedVariant?.images"
      :key="`sp-${index}`"
      class="flex flex-col items-center justify-center"
    >
      <div class="relative swiper-zoom-container">
        <NuxtImg
          :src="getImage(image?.medium)"
          width="1200"
          height="1200"
          loading="lazy"
          fit="outside"
          :alt="image?.sort_id"
          class="z-1 object-contain"
        />
      </div>

    </SwiperSlide>
    <SwiperSlide v-if="selectedVariant?.images?.length <= 0">
      <div class="relative ">
        <NuxtImg
          fit="outside"
          alt=""
          class="rounded-lg object-contain aspect-square"
          width="1200"
          height="1200"
          src="/images/no-image.png"
        />
      </div>

    </SwiperSlide>
  </Swiper>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Thumbs, Navigation, Pagination, Scrollbar, A11y, Zoom } from "swiper/modules";
import { ClockIcon } from "@heroicons/vue/24/outline";

const { selectedVariant, productSlideBreakpoints } = await useProduct()

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import useListing from "~/composables/useListing";

const thumbsSwiper = ref(null);

const setThumbsSwiper = (swiper) => {
  thumbsSwiper.value = swiper;
};
</script>

<style scoped></style>
